<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="mb-5">
        <div id="pageList">
          <v-container grid-list-xl fluid>
            <v-layout wrap>
              <!-- Mensajería -->
              <v-flex xs12 lg12 md12>
                <v-toolbar dark color="cyan" dense>
                  <v-toolbar-title class="white--text"
                    ><v-icon>mdi-message-text</v-icon>&nbsp;{{ $t('menu.messaging') }}</v-toolbar-title
                  >
                  <v-spacer />
                  <v-chip color="cyan" text-color="white" style="text-transform: uppercase">
                    {{
                      mensajeria.estado_inicial === 1 || mensajeria.estado_inicial === true
                        ? $t('common.activado')
                        : $t('common.desactivado')
                    }}
                  </v-chip>
                </v-toolbar>
                <v-card>
                  <br clear="all" />
                  <div class="form-margen">
                    <v-layout wrap>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="mensajeria.descripcion"
                          data-v-step="0"
                          :label="$t('common.descripcion')"
                          @input="cambiaMensajeria()"
                        />
                      </v-flex>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="mensajeria.precio"
                          data-v-step="1"
                          :label="
                            mensajeria.precio > 0
                              ? $t('common.precio')
                              : $t('common.precio') + ' (' + $t('common.gratuita') + ')'
                          "
                          type="number"
                          step="0.01"
                          min="0"
                          :append-icon="!es_dingdoc ? 'mdi-currency-eur' : ''"
                          @input="cambiaMensajeria()"
                        />
                      </v-flex>
                      <v-flex xs6 style="margin-top: -25px">
                        <v-switch
                          v-model="mensajeria.estado"
                          data-v-step="2"
                          :label="
                            mensajeria.estado === 0 || mensajeria.estado === false
                              ? 'Estado: Inactivo'
                              : 'Estado: Activo'
                          "
                          color="cyan"
                          @change="cambiaMensajeria"
                        />
                      </v-flex>
                      <v-flex xs6 style="text-align: right; margin-top: -15px">
                        <v-btn
                          v-if="mensajeria.modificado"
                          data-v-step="3"
                          color="green"
                          dark
                          @click="guardarMensajeria"
                          >{{ $t('common.save') }}</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card>
              </v-flex>

              <!-- Tipos Videoconsultas -->
              <v-flex lg12 md12>
                <v-card class="mb-4">
                  <v-toolbar color="light-blue darken-1" dark flat dense cad>
                    <v-toolbar-title class="white--text"
                      ><v-icon>mdi-video</v-icon>&nbsp;{{ $t('common.videoconferences') }}</v-toolbar-title
                    >
                    <v-spacer />
                  </v-toolbar>
                  <v-card-text style="margin-top: -15px">
                    <tablavideos
                      :tipo-servicio="tipoServicio"
                      :videoconsultas="videoconsultas"
                      :payment-module="has_payment_module"
                      :tipo="0"
                      @editadoTipoVideo="editadoTipoVideo"
                      @cerrarDialog="cerrarDialogComponente"
                      @mostrarAlert="mostrarAlertChild"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex v-if="presenciales.length > 0" lg12 md12>
                <v-card class="mb-4">
                  <v-toolbar color="info" dark flat dense cad>
                    <v-toolbar-title class="white--text"
                      ><v-icon>mdi-map-marker</v-icon>&nbsp;{{ $tc('videoconsulta.presencial', 2) }}</v-toolbar-title
                    >
                    <v-spacer />
                  </v-toolbar>
                  <v-card-text style="margin-top: -15px">
                    <tablavideos
                      :videoconsultas="presenciales"
                      :tipo="1"
                      @editadoTipoVideo="editadoTipoVideo"
                      @cerrarDialog="cerrarDialogComponente"
                      @mostrarAlert="mostrarAlertChild"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <!-- Chequeos -->
              <v-flex xs12 lg12 md12>
                <v-toolbar dark color="indigo" dense>
                  <v-toolbar-title class="white--text"
                    ><v-icon>mdi-file-plus</v-icon>&nbsp;{{ $t('common.chequeos') }}</v-toolbar-title
                  >
                  <v-spacer />
                  <v-chip color="indigo" text-color="white" style="text-transform: uppercase">
                    {{
                      chequeo.estado_inicial === 1 || chequeo.estado_inicial === true
                        ? $t('common.activado')
                        : $t('common.desactivado')
                    }}
                  </v-chip>
                </v-toolbar>
                <v-card>
                  <br clear="all" />
                  <div class="form-margen">
                    <v-layout wrap>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="chequeo.descripcion"
                          data-v-step="0"
                          :label="$t('common.descripcion')"
                          @input="cambiaChequeo()"
                        />
                      </v-flex>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="chequeo.precio"
                          data-v-step="1"
                          :label="
                            chequeo.precio > 0
                              ? $t('common.precio')
                              : $t('common.precio') + ' (' + $t('common.gratuita') + ')'
                          "
                          type="number"
                          step="0.01"
                          min="0"
                          append-icon="mdi-currency-eur"
                          @input="cambiaChequeo()"
                        />
                      </v-flex>
                      <v-flex xs6 style="margin-top: -25px">
                        <v-switch
                          v-model="chequeo.estado"
                          data-v-step="2"
                          :label="
                            chequeo.estado === 0 || chequeo.estado === false ? 'Estado: Inactivo' : 'Estado: Activo'
                          "
                          color="indigo"
                          @change="cambiaChequeo"
                        />
                      </v-flex>
                      <v-flex xs6 style="text-align: right; margin-top: -15px">
                        <v-btn v-if="chequeo.modificado" data-v-step="3" color="green" dark @click="guardarChequeo">{{
                          $t('common.save')
                        }}</v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card>
              </v-flex>

              <!-- Suscripciones -->
              <v-flex lg12 md12>
                <v-card class="mb-4">
                  <v-toolbar color="deep-purple" dark flat dense cad>
                    <v-toolbar-title class="white--text"
                      ><v-icon>mdi-star</v-icon>&nbsp;{{ $t('suscripciones.suscripciones') }}</v-toolbar-title
                    >
                    <v-spacer />
                  </v-toolbar>
                  <v-card-text style="margin-top: -15px">
                    <div>
                      <v-btn color="deep-purple" dark @click.native="mostrarDialogSuscripcion = true">
                        {{ $t('suscripciones.nueva') }}
                      </v-btn>
                    </div>
                    <suscripciones
                      :suscripciones="subscriptions"
                      :pacientes="pacientes"
                      :servicios="servicios"
                      :payment-module="has_payment_module"
                      :mostrar-dialog="mostrarDialogSuscripcion"
                      @cerrarDialog="cerrarDialogComponente"
                      @mostrarAlert="mostrarAlertChild"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>

          <!-- Modal Confirmar  -->
          <span slot="widget-content">
            <v-dialog v-model="dialog_confirm.dialog" dialog_confirm max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ dialog_confirm.name }}</span>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p>{{ dialog_confirm.text }}</p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="grey darken-1" text @click.native="dialog_confirm.dialog = false"
                    ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
                  >
                  <v-btn
                    color="red darken-1"
                    text
                    @click.native="
                      dialog_confirm.dialog = false;
                      confirmaDialogo('confirm');
                    "
                    ><v-icon>{{ dialog_confirm.icon }}</v-icon> {{ $t('common.confirm') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>

          <!-- Modal Nuevo -->
          <span slot="widget-content">
            <v-dialog v-model="dialog_new.dialog" dialog_new max-width="600px" scrollable>
              <v-card>
                <v-card-title>
                  <span class="headline">{{
                    nuevotipo.tipo === 0 ? $t('tipovideo.nuevo_titulo') : $t('tipovideo.nuevo_presencial')
                  }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm9>
                        <v-text-field
                          v-model="nuevotipo.nombre"
                          :rules="[rules.required]"
                          :label="$t('common.name')"
                          type="text"
                        />
                      </v-flex>
                      <v-flex xs12 sm3>
                        <v-select
                          v-model="nuevotipo.tipoVideo"
                          :items="tipoServicio"
                          style="width: 99%"
                          :label="$t('videoconsulta.tipo')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex xs12 lg12 md12>
                        <v-textarea
                          v-model="nuevotipo.descripcion"
                          :label="$t('common.descripcion')"
                          class="textarea_videoconsulta"
                          rows="2"
                        />
                      </v-flex>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="nuevotipo.precio"
                          :label="$t('common.precio')"
                          type="number"
                          step="0.01"
                          min="0"
                          :append-icon="!es_dingdoc ? 'mdi-currency-eur' : ''"
                          @input="cambiaVideoconsulta(0, 'nuevo')"
                        />
                      </v-flex>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="nuevotipo.duracion"
                          :label="$t('common.duracion')"
                          type="number"
                          min="1"
                          max="1440"
                          suffix="min."
                          append-icon="mdi-clock-outline"
                          @input="cambiaVideoconsulta(0, 'nuevo')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="grey darken-1" text @click.native="dialog_new.dialog = false"
                    ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
                  >
                  <v-btn color="blue darken-1" text @click.native="confirmaDialogo('nuevo')"
                    ><v-icon>mdi-content-save</v-icon> {{ $t('common.confirm') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>

          <!-- Snackbar para mostrar avisos -->
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="alerta.color"
            top="top"
            :multi-line="mode === 'multi-line'"
            :vertical="mode === 'vertical'"
          >
            <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
            >&nbsp;{{ alerta.texto }}
          </v-snackbar>

          <div class="text-center">
            <v-dialog v-model="dialogSalirSinGuardar" width="500">
              <v-card>
                <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
                <v-divider />
                <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{
                    $t('common.cancel')
                  }}</v-btn>
                  <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-tour name="myTour" :steps="steps" :options="myOptions" />
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Suscripciones from '@/components/ehealth/ajustes/Suscripciones.vue';
import Tablavideos from '@/components/ehealth/ajustes/Tablavideos.vue';
import { tiposVideoconsulta, saveSettingServiceUrl, getHeader, listServicesUrl } from '@/config/config';

export default {
  components: {
    Suscripciones,
    Tablavideos,
  },

  data: () => ({
    dialogSalirSinGuardar: false,
    pageNext: {},
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    videoconsultas: [],
    presenciales: [],
    pacientes: [],
    mensajeria: {
      estado: 0,
      estado_inicial: 0,
      precio: 0,
      modificado: false,
      descripcion: '',
    },
    has_payment_module: false,
    chequeo: {},
    colors: [],
    nuevotipo: { id: 0, descripcion: '', nombre: '', duracion: '', precio: '', tipoVideo: 0 },
    dialog_confirm: { name: '', text: '', icon: '', tipo: 0, dialog: false },
    dialog_new: { dialog: false },
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    tipoServicio: [
      { id: 0, name: 'Videoconsulta' },
      { id: 1, name: 'Presencial' },
    ],
    rules: {},
    subscriptions: [],
    mostrarDialogSuscripcion: false,
    mostrarDialogTablaVideos: false,
    servicios: [],
    steps: [
      { target: '[data-v-step="0"]', content: '' },
      { target: '[data-v-step="1"]', content: '' },
      { target: '[data-v-step="2"]', content: '' },
      { target: '[data-v-step="2"]', content: '' },
    ],
    myOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: 'Skip tour',
        buttonPrevious: 'Previous',
        buttonNext: 'Next',
        buttonStop: 'Finish',
      },
    },
    es_dingdoc:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] === 'Dingdoc'
        ? true
        : false,
  }),

  created() {
    this.rules = {
      required: value => !!value || this.$t('common.obligatorio'),
    };
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    if (authUser && authUser.rol !== 1) {
      this.$router.push('/ajustes/servicios');
    }
    this.authUser?.id &&
      this.$http
        .get(listServicesUrl, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.has_payment_module = response.data.paymentModule;
            this.videoconsultas = response.data.videoconsultationsServices;
            this.presenciales = response.data.faceToFaceServices;
            this.mensajeria = response.data.messageService;
            this.mensajeria.estado_inicial = response.data.messageService.estado;
            this.servicios.push({ id: 0, name: this.$t('menu.messaging') });
            this.videoconsultas.forEach(videconsulta => {
              videconsulta.estado_inicial = videconsulta.estado;
              videconsulta.modificado = false;
              this.servicios.push({ id: videconsulta.id, name: videconsulta.nombre });
            });
            this.pacientes = response.data.patients;
            this.pacientes.unshift({ value: 0, label: this.$t('common.cualquiera') });
            this.subscriptions = response.data.subscriptionsServices;
            this.chequeo = response.data.chequeo;
            this.tipoServicio[0]['name'] = this.$t('videoconsulta.singular');
            this.tipoServicio[1]['name'] = this.$tc('videoconsulta.presencial', 1);
          }
        })
        .catch(() => {
          this.$toast.error(this.$t('errors.try_again'));
        });
  },

  methods: {
    cambiarEstado(tipo) {
      this.videoconsultas[tipo].estado = this.videoconsultas[tipo].stade === 'Yes' ? 2 : 1;
      this.videoconsultas[tipo].modificado = true;
    },

    cambiarEstadoMensajeria() {
      this.mensajeria.estado = this.mensajeria.estado === 1 ? 2 : 1;
      this.mensajeria.modificado = true;
    },

    cambiaVideoconsulta(tipo, t) {
      if (t === 'nuevo') {
        this.nuevotipo.precio = this.nuevotipo.precio === '' || this.nuevotipo.precio < 0 ? 0 : this.nuevotipo.precio;
        this.nuevotipo.duracion =
          this.nuevotipo.duracion === '' || this.nuevotipo.duracion < 1 ? 1 : this.nuevotipo.duracion;
      } else {
        this.videoconsultas[tipo].modificado = true;
        if (this.videoconsultas[tipo].precio === '' || this.videoconsultas[tipo].precio < 0) {
          this.videoconsultas[tipo].precio = 0;
        } else if (this.videoconsultas[tipo].duracion === '' || this.videoconsultas[tipo].duracion < 1) {
          this.videoconsultas[tipo].duracion = 1;
        }
      }
    },

    cambiaChequeo() {
      this.chequeo.modificado = true;
      if (this.chequeo.precio === '' || this.chequeo.precio < 0) {
        this.chequeo.precio = 0;
      }
    },

    cambiaMensajeria() {
      this.mensajeria.modificado = true;
      if (this.mensajeria.precio === '' || this.mensajeria.precio < 0) {
        this.mensajeria.precio = 0;
      }
    },

    resetNuevo() {
      this.nuevotipo.descripcion = '';
      this.nuevotipo.nombre = '';
      this.nuevotipo.duracion = '';
      this.nuevotipo.precio = '';
    },

    postSaveTipo(tipo) {
      if (tipo === -1 && this.nuevotipo.nombre === '') {
        this.alerta.texto = this.$t('errors.form_invalid');
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-alert-circle';
        this.snackbar = true;
      } else {
        this.dialog_new.dialog = false;
        let postTipo = this.mensajeria;
        if (tipo === 'mensajeria' || tipo === 'chequeo') {
          postTipo = tipo === 'mensajeria' ? this.mensajeria : this.chequeo;
          postTipo.tipo = tipo;
        } else {
          postTipo = tipo === -1 ? this.nuevotipo : this.videoconsultas[tipo];
          postTipo.tipo = 'videoconsulta';
        }
        this.$http
          .post(saveSettingServiceUrl, { user_id: this.authUser.id, tipo: postTipo }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data.estado === '1') {
              this.alerta.texto = this.$t('success.save_tipo', { name: tipo });
              this.alerta.color = 'success';
              this.alerta.icono = 'mdi-check';
              if (tipo === 'mensajeria') {
                this.mensajeria.modificado = false;
                this.mensajeria.estado_inicial = this.mensajeria.estado;
              } else if (tipo === 'chequeo') {
                this.chequeo.modificado = false;
                this.chequeo.estado_inicial = this.chequeo.estado;
              } else if (tipo === -1) {
                // nuevo tipo
                this.videoconsultas.push(response.data.tipo);
              } else {
                // editar
                this.videoconsultas[tipo].modificado = false;
                this.videoconsultas[tipo].estado_inicial = this.videoconsultas[tipo].estado;
              }
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.color = 'error';
              this.alerta.icono = 'mdi-alert-circle';
            }
            this.snackbar = true;
          });
      }
    },

    postDeleteTipo(tipo) {
      this.$http
        .post(
          tiposVideoconsulta,
          { user_id: this.authUser.id, accion: 'eliminar', tipo_id: this.videoconsultas[tipo].id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.alerta.texto = this.$t('success.delete_common');
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-delete';
            this.videoconsultas.splice(tipo, 1);
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        });
    },

    confirmaDialogo(tipo_dialog) {
      if (tipo_dialog === 'nuevo') {
        this.postSaveTipo(-1);
      } else {
        if (this.dialog_confirm.icon === 'save') {
          this.postSaveTipo(this.dialog_confirm.tipo);
        } else {
          this.postDeleteTipo(this.dialog_confirm.tipo);
        }
      }
    },

    editadoTipoVideo(data) {
      if (data.tipo === 0) {
        this.videoconsultas.splice(data.indice, 1);
        this.presenciales.push(data.item);
      } else {
        this.presenciales.splice(data.indice, 1);
        this.videoconsultas.push(data.item);
      }
      this.alerta.texto = this.$t('success.save_common');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-check';
      this.snackbar = true;
    },

    showDialogConfirm(name, text, icon, tipo) {
      this.dialog_confirm.name = name;
      this.dialog_confirm.text = text;
      this.dialog_confirm.icon = icon;
      this.dialog_confirm.tipo = tipo;
      this.dialog_confirm.dialog = true;
    },

    guardarVideonconsulta(tipo) {
      if (
        this.videoconsultas[tipo].estado_inicial !== this.videoconsultas[tipo].estado &&
        this.videoconsultas[tipo].estado === 2
      ) {
        this.showDialogConfirm(this.$t('tipovideo.guardar'), this.$t('tipovideo.confirm_desactivar'), 'save', tipo);
      } else {
        this.postSaveTipo(tipo);
      }
    },

    guardarMensajeria() {
      if (this.mensajeria.estado_inicial !== this.mensajeria.estado && !this.mensajeria.estado) {
        this.showDialogConfirm(
          this.$t('tipovideo.guardarmensajeria'),
          this.$t('tipovideo.confirm_desactivar', { name: this.$t('common.messaging') }),
          'save',
          'mensajeria'
        );
      } else {
        this.postSaveTipo('mensajeria');
      }
    },

    guardarChequeo() {
      if (this.mensajeria.estado_inicial !== this.mensajeria.estado && this.mensajeria.estado === 2) {
        this.showDialogConfirm(
          this.$t('tipovideo.guardarchequeo'),
          this.$t('tipovideo.confirm_desactivar', { name: this.$t('common.chequeo') }),
          'save',
          'chequeo'
        );
      } else {
        this.postSaveTipo('chequeo');
      }
    },

    abrirDialogDelete(tipo) {
      this.showDialogConfirm(
        this.$t('tipovideo.confirm_delete_titulo'),
        this.$t('tipovideo.confirm_delete_texto'),
        'delete',
        tipo
      );
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },

    cerrarDialogComponente(data) {
      if (data.tipo === 'tablavideos') {
        this.mostrarDialogTablaVideos = !data.estado;
      } else {
        this.mostrarDialogSuscripcion = !data.estado;
      }
    },

    mostrarAlertChild(alerta) {
      this.alerta.texto = alerta.texto;
      this.alerta.color = alerta.tipo;
      this.alerta.icono = alerta.icono;
      this.snackbar = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    let vistaModificada = false;
    if (this.mensajeria.modificado && to['name'] !== 'Login') {
      vistaModificada = true;
    } else if (to['name'] !== 'Login') {
      for (let i = 0; i < this.videoconsultas.length; i++) {
        if (this.videoconsultas[i].modificado) {
          vistaModificada = true;
        }
      }
    }
    if (vistaModificada) {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="css">
.boton_save:hover {
  cursor: pointer !important;
}

.btn_float {
  z-index: 5;
  bottom: 60px;
  right: 8px;
}
.form-margen {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
